

@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

// Bootstrap mixins and functions
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/functions";

// Change variables here
@import "ag-app/variables";

// Bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";

// Vendor
@import "ag-app/vendor";

// ag-app mixins & functions
@import "ag-app/mixins";
@import "ag-app/functions";

// Utilities
@import "ag-app/reboot";
@import "ag-app/utilities";

// Layout
@import "ag-app/layout";

// Components
@import "ag-app/components";

// write your custom styles here!