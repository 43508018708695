/**
 * = Pricing cards
 */

.pricing-card{

    .card-header {
        background: transparent;
        z-index: 2;
    }

    .card-body{
        z-index: 2;
    }

    .pricing-value{
        width: 150px;
        height: 150px;
        border-radius: 50%;
        border-width: $border-width-xl;
        border-style: double;
        margin: 0 auto;

        span {
            line-height: 140px;
        }
    }

    .list-group-item{
        background: transparent;
        border: none;
        padding: 0.375rem 0.125rem;
        i{
            display: inline-block;
            vertical-align: middle;
            width: 35px;
        }
    }
    @include media-breakpoint-down(md){
        margin-top: 1rem;
    }
}

.pricingCardItem {
    background-color: #fff;
    display: block;
    margin: 10px 0;
    position: relative;
    border-radius: 8px;

    label {
      padding: 12px 20px;
      width: 100%;
      display: block;
      text-align: left;
      color: $secondary;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;
      border-radius: 8px;
      border: 1px solid lightgray;
      &:hover{
        border: 1px solid $primary;
      }
      
      &:before {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        content: '';
        background-color: #FFEBE7;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale3d(1, 1, 1);
        transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
        opacity: 0;
        z-index: -1;
      }

      &:after {
        width: 24px;
        height: 24px;
        content: '';
        border: 1px solid lightgray;
        background-color: #fff;
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' stroke-width='2' xz1fill-rule='nonzero'/%3E%3C/svg%3E ");
        background-repeat: no-repeat;
        background-position: -1px 0px;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        transition: all 200ms ease-in;
      }
    }

    input:checked ~ label {
      border: 1px solid $primary;

      &:before {
        transform: translate(-50%, -50%) scale3d(76, 56, 1);
        opacity: 1;
      }

      &:after {
        background-color: $primary;
        border-color: $primary;
      }
    }

    input {
      width: 24px;
      height: 24px;
      order: 1;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
    }
  }
